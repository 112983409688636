import { combineReducers } from "redux";
import loginReducer from "./LoginReducer";
import productReducer from './ProductReducer';
import shopCatReducer from './ShopCatReducer'
import productCatReducer from './ProductCatReducer'
import productSubCatReducer from './ProductSubCatReducer'
const initialState = {
  sidebarShow: 'responsive'
};

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    default:
      return state
  }
};

const rootReducer = combineReducers({
  nav: changeState,
  SupplierUser: loginReducer,
  productData: productReducer,
  shopCat: shopCatReducer,
  productCat: productCatReducer,
  productSubCat: productSubCatReducer,
});
export default rootReducer;
