import axios from 'axios';
import { fetchShopCat } from './ShopCatActions'
import { fetchProductCat } from './ProductCatActions'
import { fetchProductSubCat } from './ProductSubCatActions'

export const LOGIN_SUPPLIER_SUCCESS = "LOGIN_Supplier_SUCCESS";
export const LOGOUT_SUPPLIER_SUCCESS = "LOGOUT_Supplier_SUCCESS";
export const UPDATE_SUPPLIER_SUCCESS = "UPDATE_Supplier_SUCCESS";
export const DESTROY_SESSION = "destroy_session";

const LoginSupplierSuccess = SupplierUser => ({
  type: LOGIN_SUPPLIER_SUCCESS,
  payload: SupplierUser
});


const LogoutSupplierSuccess = SupplierUser => ({
  type: LOGOUT_SUPPLIER_SUCCESS,
  payload: SupplierUser
});

const UpdateSupplierSuccess = SupplierUser => ({
  type: UPDATE_SUPPLIER_SUCCESS,
  payload: SupplierUser
});


let axiosConfig = {
  headers: {
    'Content-Type': 'multipart/form-data',
    "Access-Control-Allow-Origin": "*",
  }
};
export const LoginSupplier = (SupplierCredentials, addToast) => {
  return dispatch => {
    //console.log('Login Ation');
        axios.post(process.env.REACT_APP_ENDPOINT + "api/Supplier/Login", SupplierCredentials, axiosConfig) //?UserName=" + SupplierCredentials.username + '&Password=' + SupplierCredentials.password
      .then(res => res.data)
      .then(user => {
        dispatch(LoginSupplierSuccess(user));
        addToast("Login Successfull", { appearance: "success", autoDismiss: true });
        dispatch(fetchShopCat());
        dispatch(fetchProductCat());
        dispatch(fetchProductSubCat());

      })
      .catch(error => {
        if (error.response.status === 401) {
          addToast("Invalid Login Credentials", { appearance: "error", autoDismiss: true });
        }
        else { console.log(error.response); }

      });
  };
};



export const LogoutSupplier = (SupplierCredentials) => {
  return dispatch => {
    //  console.log('Logout Ation');
    // console.log(SupplierCredentials);
    dispatch(LogoutSupplierSuccess(SupplierCredentials));
  };
};


export const UpdateSupplier = (SupplierCredentials) => {
  return dispatch => {
    //  console.log('Logout Ation');
    // console.log(SupplierCredentials);
    dispatch(UpdateSupplierSuccess(SupplierCredentials));
  };
};

export const onClear = () => {
  return { type: DESTROY_SESSION };
};
