import { FETCH_SHOPCAT_SUCCESS } from "../actions/ShopCatActions";

const initState = {
  shopCat: []
};

const shopCatReducer = (state = initState, action) => {
  if (action.type === FETCH_SHOPCAT_SUCCESS) {
    return {
      ...state,
      shopCat: action.payload
      
    };
  }

  return state;
};

export default shopCatReducer;
