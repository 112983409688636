import PropTypes from "prop-types";
import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { ToastProvider } from "react-toast-notifications";
import './scss/style.scss';
//import Dashboard from './views/dashboard/Dashboard';
//import UnauthenticatedRoute from './UnauthenticatedRoute';
//import AuthenticatedRoute from './AuthenticatedRoute';
//import { getIsLogin } from './util/common'
import { connect } from 'react-redux'




const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const VerifyEmail = React.lazy(() => import('./views/pages/register/verifyEmail'));
const ForgotPassword = React.lazy(() => import('./views/pages/login/ForgotPassword'));
const ResetPassword = React.lazy(() => import('./views/pages/login/ResetPassword'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));


class App extends Component {
  //constructor(props) {
  //  super();
  //}
  render() {
    return (
      <ToastProvider placement="bottom-center">
        <BrowserRouter>
          <React.Suspense fallback={loading}>
            <Switch>
              {/* {routes.map((route, idx) => {
              return route.component ? (
                <Route
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  render={props =>
                    getIsLogin() !== null
                      ? (
                        <route.component {...props} />
                      ) : (
                        <Redirect to={{ pathname: "/login" }} />
                      )
                  }
                />
              ) : null;
            })}
            <Redirect from="/" to="/dashboard" /> */}
              <Route exact path="/login" name="Login Page" render={prop =>
                //getIsLogin() === false?
                !this.props.SupplierUser.user.token ?
                  <Login {...prop} />
                  :
                  <Redirect to={{ pathname: "/" }} />
              }
              />
              <Route exact path="/forgotpassword" name="Forgot Password" render={prop =>
                !this.props.SupplierUser.user.token ?
                  <ForgotPassword {...prop} />
                  :
                  <Redirect to={{ pathname: "/" }} />
              }
              />
              <Route path="/resetpassword" name="Reset Password" render={prop =>
                !this.props.SupplierUser.user.token ?
                  <ResetPassword {...prop} />
                  :
                  <Redirect to={{ pathname: "/" }} />
              }
              />

              <Route exact path="/register" name="Register Page" render={prop =>
                ///getIsLogin() === false ?
                !this.props.SupplierUser.user.token ?
                  <Register {...prop} />
                  :
                  <Redirect to={{ pathname: "/" }} />
              } />

              <Route path="/verify-email" name="Verify Email" render={prop =>
                !this.props.SupplierUser.user.token ?
                  <VerifyEmail {...prop} />
                  :
                  <Redirect to={{ pathname: "/" }} />
              } />

              <Route exact path="/404" name="Page 404" render={prop => <Page404 {...prop} />} />
              <Route exact path="/500" name="Page 500" render={prop => <Page500 {...prop} />} />
              <Route path="/" name="Home" render={prop => <TheLayout {...prop} />} />
              {/*<ProtectedRoute exact={true} path="/" component={ props => <TheLayout {...props} />} />
*/}
              {/*  <UnauthenticatedRoute
              path="/login"
              component={Login}
              appProps={ getIsLogin() }
            />
            <UnauthenticatedRoute
              path="/register"
              component={Register}
              appProps={getIsLogin()}
            />
            <AuthenticatedRoute
              path="/"
              component={props => <TheLayout {...props} />}
              appProps={ getIsLogin() }
            />*/}
            </Switch>
          </React.Suspense>
        </BrowserRouter>
      </ToastProvider>
    );
  }
}
App.propTypes = {
  SupplierUser: PropTypes.object,
};
const mapStateToProps = state => ({
  SupplierUser: state.SupplierUser
});

export default connect(mapStateToProps)(App);
