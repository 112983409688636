import { LOGIN_SUPPLIER_SUCCESS, LOGOUT_SUPPLIER_SUCCESS, UPDATE_SUPPLIER_SUCCESS, DESTROY_SESSION } from "../actions/LoginActions";


const initState = {
  user: ''

};

const LoginReducer = (state = initState, action) => {
  if (action.type === LOGIN_SUPPLIER_SUCCESS) {
    return {
      ...state,
      user: action.payload

    };
  }
  if (action.type === LOGOUT_SUPPLIER_SUCCESS) {
    return {
      ...state,
      user: action.payload

    };
  }

  if (action.type === UPDATE_SUPPLIER_SUCCESS) {
    return {
      ...state,
      user: action.payload

    };
  }

  if (action.type === DESTROY_SESSION)
    state = null;


  return state;
};

export default LoginReducer;
