import axios from 'axios';

export const FETCH_PRODUCTCAT_SUCCESS = "FETCH_PRODUCTCAT_SUCCESS";

const fetchProductCatSuccess = productCat => ({
  type: FETCH_PRODUCTCAT_SUCCESS,
  payload: productCat
});


let axiosConfig = {
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
    "Access-Control-Allow-Origin": "*",
  }
};
export const fetchProductCat = () => {
  return async dispatch => {
    await axios.get(process.env.REACT_APP_ENDPOINT + "api/product/GetProductsCategory", axiosConfig)

      .then((response) => {
        dispatch(fetchProductCatSuccess(response.data));
        //console.log(response.data);
      })
      .catch((err) => { console.log(err) });

  };
};

