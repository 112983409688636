import axios from 'axios';

export const FETCH_SHOPCAT_SUCCESS = "FETCH_SHOPCAT_SUCCESS";

const fetchShopCatSuccess = shopCat => ({
  type: FETCH_SHOPCAT_SUCCESS,
  payload: shopCat
});


let axiosConfig = {
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
    "Access-Control-Allow-Origin": "*",
  }
};
export const fetchShopCat = () => {
  return async dispatch => {
    await axios.get(process.env.REACT_APP_ENDPOINT + "api/product/GetShopCategories", axiosConfig)

      .then((response) => {
        dispatch(fetchShopCatSuccess(response.data));
        //console.log(response.data);
      })
      .catch((err) => { console.log(err) });
      
  };
};

