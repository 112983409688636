import axios from 'axios';

export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";

const fetchProductsSuccess = products => ({
  type: FETCH_PRODUCTS_SUCCESS,
  payload: products
});


let axiosConfig = {
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
    "Access-Control-Allow-Origin": "*",
  }
};
export const fetchProducts = (id) => {
  return dispatch => {
    axios.get(process.env.REACT_APP_ENDPOINT + "api/product/getproductsBySubmissionId?SubmittedBy="+id, axiosConfig)
      .then(res => res.data)
      .then(products => dispatch(fetchProductsSuccess(products)));
  };
};

export const fetchProductsForAdmin = (productStatus) => {
  return dispatch => {
    if (productStatus !== null) {
      axios.get(process.env.REACT_APP_ENDPOINT + "api/product/getproductsopu/" + productStatus, axiosConfig)
        .then(res => res.data)
        .then(products => dispatch(fetchProductsSuccess(products)));
    }
    else {
      axios.get(process.env.REACT_APP_ENDPOINT + "api/product/getproductsopu", axiosConfig)
        .then(res => res.data)
        .then(products => dispatch(fetchProductsSuccess(products)));
    }
  };
};
