import { FETCH_PRODUCTSUBCAT_SUCCESS } from "../actions/ProductSubCatActions";

const initState = {
  productSubCat: []
};

const productSubCatReducer = (state = initState, action) => {
  if (action.type === FETCH_PRODUCTSUBCAT_SUCCESS) {
    return {
      ...state,
      productSubCat: action.payload,
    };
  }

  return state;
};

export default productSubCatReducer;
